import axios from "axios";
import store from '@/store'
import localStorage  from '@/utils/userToken'

// 创建实例
const service = axios.create({
  // 请求域名
  // baseURL: 'http://persp.gordony.top',
  // baseURL: 'https://doc.perspcloud.com',
  baseURL: 'https://doc.perspcloud.com',
  // 设置超时时间
  timeout: 1000 * 50,
  // 请求头
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With':'XMLHttpRequest'
  }
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    //const token = 'xxx' // 登录后生成用于识别用户身份，项目不需要直接去掉
    //config.headers['Authorization'] = token || ''
    // 在发送请求之前做什么
    // if (config.method === 'post') {
    //     // post请求需要序列化
    //     config.data = qs.stringify(config.data)
    //     console.log('config.data--------------------------->',config.data)
    // }
    // console.log('获取token',localStorage.getToken());
    if(localStorage.getToken() && localStorage.getToken().access_token){
      config.headers['Authorization'] = `Bearer ${localStorage.getToken().access_token}`;
    }

    return config;
  },
  error => {
    // console.error('error: ', error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  //成功
  (response) => {
    if (response.status === 200) { //根据后端定义响应状态进行相应的处理,例：1数据返回成功，2未登录
      return response.data
    }
  },
  async (error) => {
    // console.log(error.response, '拦截器响应')
    if (error.response.status == 401) { 
      if (localStorage.getToken() && localStorage.getToken().refresh_token) {
        try {
          const {data:res} = await axios({ url: `https://doc.perspcloud.com/v1/login/refresh`, method: 'get', headers: { 'Authorization': `Bearer ${localStorage.getToken().refresh_token}` } })
          // console.log(res, '获取新的token')
          store.commit('user/removeUserInfo')
          if (res.status == 1) {
            store.commit('setUserInfo', res.data)
          } else { 
            store.commit('user/removeUserInfo')
            store.commit('user/setIsLogin', true)
          }
        } catch (e) {
          console.log(e,'error')
          store.commit('user/removeUserInfo')
          store.commit('user/setIsLogin', true)
         }   
      }else{ 
      store.commit('user/removeUserInfo')
      store.commit('user/setIsLogin', true)
      }
      
      
    }
    return Promise.reject(error);
  }
)

export default service