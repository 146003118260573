//用户Token
import storage from 'good-storage'
import passKey from '@/utils/crypto'
const TokenKey = 'login_template_token'
const InfoKey="user_info"

//存储个人用户信息
const localStorage = {
    // 获取个人信息
    getInfo: function () { 
      let reduceKey = storage.get(InfoKey)
      if(storage.get(InfoKey)){
        reduceKey = JSON.parse(JSON.parse(passKey.decrypt(storage.get(InfoKey))));       //解密
    }
    
    // console.log('解密---------------->',reduceKey);
    return reduceKey;   
    },
    // 设置个人信息
    setInfo:function(tokenVal){
    // console.log('设置token-=-=-=---------=',tokenVal);
    let addKey = tokenVal;
    if(tokenVal){
        addKey = passKey.encrypt(JSON.stringify(tokenVal));       //加密
    }

    // console.log('加密---------------->',addKey);
    return storage.set(InfoKey,addKey);    
    },
    //获取token
    getToken: function(){
        // console.log('获取token-=-=-=---------=',storage.get(TokenKey));
        let reduceKey = storage.get(TokenKey);
        if(storage.get(TokenKey)){
            reduceKey = JSON.parse(JSON.parse(passKey.decrypt(storage.get(TokenKey))));       //解密
        }
        
        // console.log('解密---------------->',reduceKey);
        return reduceKey;    
    },
    //设置token
    setToken:function(tokenVal){
        // console.log('设置token-=-=-=---------=',tokenVal);
        let addKey = tokenVal;
        if(tokenVal){
            addKey = passKey.encrypt(JSON.stringify(tokenVal));       //加密
        }

        // console.log('加密---------------->',addKey);
        return storage.set(TokenKey,addKey);    
  },
    //移除token
  removeToken: function () {
      storage.remove(InfoKey)
        return storage.remove(TokenKey);
    },
    //清作token
    clearToken:function(){
        return storage.clear(); 
    },
}
export default localStorage;