import request from '@/utils/request'


//搜索用户--get请求带参
// export const searchUser = (search) => request({url:`/v1/front/search_user/${search}`,method: 'get'})
// 病例推荐---get不带参请求
// export const caseTop = () => request({url:`/v1/front/case_top`,method: 'get'})

//post请求---带参
// export const login = (loginInfo) => request({url:`/v1/login/login`,method:'post',data:loginInfo})

//post请求---不带参案例
export const loginPost = () => request({url:`/v1/login/login`,method:'post'})


/*以上是请求例子 到时候会删除*/

//首页配置图
export const headerImg = () => request({url:`/v1/front/header_img`,method: 'get'})

//病例推荐
export const caseTop = () => request({url:`/v1/front/case_top`,method: 'get'})

//医师推荐
export const doctorTop = (uid) => request({url:`/v1/front/doctor_top?user_id=${uid}`,method: 'get'})

//科室类型
export const department = () => request({url:`/v1/branch-class/department`,method: 'get'})

//全部病例
export const allCase = (type,page) => request({url:`/v1/front/class_list_article?type=${type}&page=${page}`,method: 'get'})

//头部--系统消息列表
export const systemMesList = (page) => request({url:`/v1/chat/announcement_list?page=${page}`,method: 'get'})

//头部--用户名+密码登录
export const userPasLogin = (loginInfo) => request({url:`/v1/login/login`, method: 'post',data:loginInfo})

//头部--手机验证码 登录/注册
export const phoneRegisterLogin = (phone,code) => request({url:`/v1/login/login_phone?phone=${phone}&code=${code}`,method: 'get'})

//头部--手机号获取验证码
//type { 1:短信登录 2:修改密码 }
export const getPhoneCode = (phone,type) => request({url:`/v1/login/verify?phone=${phone}&t=${type}`,method: 'get'})

// 搜索病例
export const searchCase = (search,page) => request({url:`/v1/front/search_case?search=${search}&page=${page}`,method: 'get'})

//搜索用户 
export const searchUser = (id,search,page) => request({url:`/v1/front/search_user/${id}?search=${search}&page=${page}`,method: 'get'})

//获取通知消息数量/首页导航栏
export const noticeListNums = () => request({url:`/v1/chat/notice_list_nums`,method: 'get'})

//头部--全部已读
export const haveReadAll = (id) => request({url:`/v1/have-read/have_read_all`,method: 'post',data:id})

//头部--公告已读
export const haveReadAnn = (ann_ids) => request({url:`/v1/have-read/have_read_ann`,method: 'post',data:ann_ids})

//头部--动态已读
export const haveReadNotice = (ids) => request({url:`/v1/have-read/have_read_notice`,method: 'post',data:ids})
