<template>
  <div>
    <!-- 新的 -->
    <div class="m-new-feedback">
      <el-popover placement="left">
        <div class="m-new-prompt">
          <p>商务合作</p>
          <p>support@medinsightech.com</p>
        </div>
        <div slot="reference" class="m-new-button">    
          <i class="el-icon-user"></i>
          合作
        </div>
      </el-popover>
      <el-popover placement="left">
        <div class="m-new-prompt">
          <p>信息反馈</p>
          <p>support@medinsightech.com</p>
        </div>
        <div slot="reference" class="m-new-button" >
          <i class="el-icon-message"></i>
          <p>反馈</p>
        </div>
      </el-popover>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Feedback',
  data () {
    return {
      coopVisible: false,     //合作
      fdVisible: false,       //反馈
      isShow:true,
    }
  },
  created () {

  },
  methods: {
  }
}
</script>

<style scoped lang="less">
/*新的*/
.m-new-feedback{
  width: 64px;
  height: auto;
  padding: 4px;
  position: fixed;
  bottom: 8%;
  right: 1%;
  z-index: 555;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(66,93,121,0.1);
  border-radius: 8px; 
}
.m-new-button{
  width:56px;
  height: 56px;
  border-radius: 8px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 9px 0;
  cursor: pointer;
  font-size: 12px;
  color: #666666;
  line-height: 20px;
}
.m-new-button:hover{
  background: #F3F4F7;
}
.m-new-button i{
  font-size: 20px;
}
.m-new-prompt{
  padding: 16px;
}
.m-new-prompt p:nth-child(1){
  font-size: 14px;
  color: #0066FF;
  line-height: 20px;
}
.m-new-prompt p:nth-child(2){
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}
</style>