<template>
  <div id="app">
    <Header v-if="isHeader"></Header>
    <router-view v-on:headerShow="headerShow" v-on:footerShow="footerShow" v-on:feedbackShow="feedbackShow" />
    <Feedback v-if="isFeedback"></Feedback>
    <Footer v-if="isFooter"></Footer>
    <el-dialog title="认证提示" :visible.sync="hintDialog" width="30%" @close="$store.commit('user/setHintDialog',false)">
      <span>请先认证,认证后才可以操作</span> <br>
      <span>如需加急，请邮件至<a class="support" href="mailto:support@medinsightech.com">support@medinsightech.com</a></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$store.commit('user/setHintDialog',false)">取 消</el-button>
        <el-button type="primary" @click="handelHint">认证</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import Feedback from '@/components/feedback'
import { mapState } from "vuex"
export default {
  name: 'App',
  data () {
    return {
      isHeader: true,      //是否显示头部
      isFooter: true,      //是否显示尾部
      isFeedback: true,
    }
  },
  computed: {
    // 将 store 映射到当前组件
    ...mapState('user', ['hintDialog'])
  },
  components: {
    Header,
    Footer,
    Feedback
  },
  methods: {
    //是否显示头部
    headerShow (bool) {
      this.isHeader = bool;
    },
    //是否显示尾部
    footerShow (bool) {
      this.isFooter = bool;
    },
    //是否显示合作反馈
    feedbackShow (bool) {
      this.isFeedback = bool;
    },
    handelHint () {
      let currentUserId = this.$localStorage.getInfo().id;
      this.$router.push(
        {
          path: `/pc/accountinfo`,
          query: {
            user_id: currentUserId,
            activeName: '2'
          }
        }
      )
      this.$store.commit('user/setHintDialog', false);
    },
  }
}
</script>

<style lang="less">
@import url(@/assets/css/common.css);
#app {
}
</style>
