import Vue from "vue";
import Vuex from "vuex";
import user from './user'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user // 用户相关的信息
  },
})

export default store