import localStorage from '@/utils/userToken'
import {getUserInfo }  from '@/api/User/user.js'
export default {
  namespaced: true, // 开启命名空间
  state: {
    userInfo: localStorage.getInfo() || {}, 
    isLogin: false,
    hintDialog: false,
  },
  mutations: {
    setIsLogin (state, isLogin) {
      state.isLogin=isLogin
    },
    setHintDialog (state, flag) { 
      state.hintDialog=flag
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      localStorage.setInfo(userInfo)
    },
    removeUserInfo(state) {
      state.userInfo = null
      localStorage.removeToken()
    },
  },
  actions: {
  // 上下文对象: store 对象
  async getUserInfo (context) {
    // 发请求获取数据
    // this.$http('/my/userinfo')
    // console.log(this) // 是 store 对象, 所以没有 Vue 原型上的属性和方法
    // console.log(context) // 同样无法使用 Vue 原型的属性和方法
    const res = await getUserInfo()
      if (res.code == 200 && res.status == 1) { 
        let user = { ...res.data, all_space: Math.round(Number(res.data.all_space) / 1024), use_space: parseFloat((Number(res.data.use_space) / 1024).toFixed(2)) }
        context.commit('setUserInfo',user)
      } 
  },
    logout(context) {
      context.commit('removeUserInfo') 
    }
  }
}

