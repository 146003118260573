<template>
  <div class="m-login-box" v-show="isFram">
    <div :class="[isMobile?'m-s-login':'m-lo-frame']">
      <i class="el-icon-close m-lo-close" @click="getClose"></i>
      <el-tabs class="tabLogin" v-model="activeName" @tab-click="handleClick(activeName)">
        <el-tab-pane label="密码登录" name="first" >
          <div class="m-lo-fromBox">
            <!-- from表单 -->
            <el-form ref="form" :model="form" :rules="rules" :label-position="labelPosition">
              <el-form-item label="" prop="username">
                <el-input v-model="form.username" placeholder="账号" />
              </el-form-item>
              <div class="m-lo-from">
                <el-form-item label="" prop="password">
                  <el-input type="password" v-model="form.password" placeholder="密码" />
                </el-form-item>
              </div>
              <el-form-item>
                <div class="m-lo-agree">
                  <span>未注册过医视际的手机号，我们将自动帮你注册账号,登录或完成注册即代表你同意</span>
                  <!-- <label @click="userAgreementVisible = true">用户协议</label>和<label>隐私政策</label> -->
                  <router-link to="/pc/notice/1">用户协议</router-link>和<router-link to="/pc/notice/3">隐私政策</router-link>
                </div>
              </el-form-item>
              <el-form-item class="m-lo-button">
                <!-- <p class="m-lo-but" @click="accountLogin('form')">登录</p> -->
                <!-- <p class="m-lo-but" :class="btnBlue ? 'active' : '' " @click="accountLogin('form')">登录</p> -->
                <el-button type="primary" class="m-btn" @click="accountLogin('form')"
                  :disabled="!(form.password&&form.password.toString().length>5)">{{loginStatus}}</el-button>
              </el-form-item>
              <p class="m-lo-from-forpass" @click="forgetPas()">忘记密码？</p>
            </el-form>
            
          </div>
        </el-tab-pane>
        <el-tab-pane label="短信登录" name="second">
          <div class="m-lo-fromBox">
            <!-- from表单 -->
            <el-form ref="confiForm" :model="confiForm" :rules="rules" :label-position="labelPosition">
              <el-form-item label="" prop="phone">
                <el-input v-model="confiForm.phone" placeholder="手机号" />
              </el-form-item>
              <!-- <div class="m-lo-from">
                  <el-form-item label="" prop="phoneCode">
                      <el-input  v-model="confiForm.phoneCode" placeholder="验证码" :disabled="phoneCodeTrue"/>
                  </el-form-item>
                  <p v-show="isshow" class="m-lo-from-forpass" @click="getCode('confiForm')">获取验证码</p>
                  <p v-show="!isshow" class="m-lo-from-forpass">{{count}}s后重新获取</p>
              </div> -->
            </el-form>
            <el-form ref="verifyForm" :model="verifyForm" :rules="rules" :label-position="labelPosition">
              <div class="m-lo-from">
                <el-form-item label="" prop="phoneCode" style="width:60%">
                  <el-input v-model="verifyForm.phoneCode" placeholder="验证码" :disabled="phoneCodeTrue" />
                </el-form-item>
                <p v-show="isshow" class="m-lo-code-box" @click="getCode('confiForm')">获取验证码</p>
                <p v-show="!isshow" class="m-lo-code-box">{{count}}s后重新获取</p>
              </div>
              <el-form-item>
                <!-- type="button" -->
                <!-- <p class="m-lo-but" :class="btnBlue ? 'active' : '' " @click="loginRegis('confiForm','verifyForm')">登录/注册</p> -->
                <div class="m-lo-agree">
                  <span>未注册过医视际的手机号，我们将自动帮你注册账号,登录或完成注册即代表你同意</span>
                  <label @click="dialogTableVisible = true">用户协议</label>和<label>隐私政策</label>
                  <router-link to="/pc/notice/1">用户协议</router-link>和<router-link to="/pc/notice/3">隐私政策</router-link>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="m-btn" @click="loginRegis('confiForm','verifyForm')"
                  :disabled="!(verifyForm.phoneCode&&verifyForm.phoneCode.toString().length>3)">{{loginStatus}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
//创建一个 debounced（防抖动）函数，该函数会从上一次被调用后
import _ from 'lodash';
export default {
  name: 'Login',
  data () {
    //验证用户名-- username: "MIS15692130068"
    var validateAccount = (rule, value, callback) => {
      const userNameReg = /^[\u4e00-\u9fa5_a-zA-Z0-9_]{3,18}$/;
      if (value === '') {
        callback(new Error('请输入账号'));
      } else if (!userNameReg.test(value)) {
        callback(new Error('账号不包含特殊字符,不小于4位不大于18位'));
      } else {
        this.getAccountPas();
        callback();
      }
    };
    //验证密码
    var validatePass = (rule, value, callback) => {
      const passReg = /^\w{6,18}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (!passReg.test(value)) {
        callback(new Error('输入的必须是6-18位的任何字符!!!'));
      } else {
        this.getAccountPas();
        callback();
      }
    };
    //验证手机号
    var validateMobile = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!phoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        // if (this.confiForm.phone !== '') {
        //     this.$refs.confiForm.validateField('phone');
        // }
        callback();
      }
    };
    //验证手机号验证码
    var validatePhoneCode = (rule, value, callback) => {
      const phoneCodeReg = /^[0-9]{6}$/;
      if (value === '') {
        callback(new Error('请输入手机号验证码'));
      } else if (!phoneCodeReg.test(value)) {
        callback(new Error('请输入正确的验证码'));
      } else {
        // if (this.confiForm.phoneCode !== '') {
        //     this.$refs.confiForm.validateField('phoneCode');
        // }
        callback();
      }
    };

    //不包含特殊字符，不小于4位不大于18位.
    return {
      form: {                     //账号登录表单
        username: '',
        password: '',
      },
      confiForm: {
        phone: '',               //手机号
        operType: 1,            //1:短信登录 2:修改密码
      },
      verifyForm: {
        phoneCode: '',          //手机号验证码
      },
      phoneCodeTrue: true,         //手机验证码是否获取成功
      isAsyncPhone: true,         //判断短信是否接收成功
      labelPosition: 'top',
      isFram: true,
      activeName: 'first',       //账号登录与验证码登录切换
      btnBlue: false,               //获取验证码成功后按钮颜色
      count: '',
      timer: null,
      isshow: true,
      loginStatus: '登录',
      isMobile: false,
      //验证规则
      rules: {
        //用户名
        username: [
          { validator: validateAccount, trigger: 'blur' }
        ],
        //密码
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        //手机号验证码
        phone: [
          { validator: validateMobile, trigger: 'blur' }
        ],
        //验证手机号验证码
        phoneCode: [
          { validator: validatePhoneCode, trigger: 'blur' }
        ],
      },
      userAgreementVisible: false,        //
    }
  },
  props: ['isShowLogo'],
  created () {},
  mounted () {
    this.showMobile()
  },
  methods: {
    handleClick (value) {
      this.btnBlue = false

      if (value == "first") {
        this.form = {}
        this.loginStatus = "登录"
      } else {
        this.confiForm = {
          phone: '',               //手机号
          operType: 1,            //1:短信登录 2:修改密码
        },
          this.loginStatus = "登录/注册"
      }
    },
    onSubmit () {
    },
    //获取输入账号与密码值
    getAccountPas () {
      const { username, password } = this.form;
      if (username && password) {
        this.btnBlue = true;
      }
    },
    //input验证提示
    verifyInput (param) {
      this.$refs[param].validate((valid) => {
        if (valid) {
          this.btnBlue = true;
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode: _.debounce(function (param) {
      this.$refs[param].validate((valid) => {
        if (valid) {
          //已输入手机号
          if (!this.isAsyncPhone) {
            this.$message({
              message: '手机号发送成功，请查看手机获取验证码',
              type: 'warning'
            });
          } else {
            this.asyncGetPhoneCode();
          }
        } else {
          return false;
        }
      });
    }, 0),
    //关闭
    getClose () {
      this.isFram = !this.isShowLogo;
      this.$emit('parLogin', { loginClose: this.isFram });
    },
    //用户名+密码登录
    async pasUserPasLogin () {
      try {
        this.loginStatus = "登录中....."
        this.btnBlue = false
        let result = await this.$API.user.userPasLogin(this.form);
        if (result.status == 1) {
          this.$message({
            message: result.message,
            type: 'success'
          });
          // setTimeout(() => {
          this.isFram = false;
          this.btnBlue = false;
          this.$emit('accountLoginSucc', { accLogSucData: result.data });
          // this.$store.dispatch('user/getUserInfo') //将个人信息存入vuex
          // this.$store.commit('user/setUserInfo', result.data) 
          // }, 4000);
        } else if (result.status == 0) {
          this.$message.error(result.message);
          this.loginStatus = "登录"
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //验证码登录+注册
    async asyncPhoneRegisterLogin () {
      try {
        const { phone } = this.confiForm;
        const { phoneCode } = this.verifyForm;
        this.loginStatus = "登录中....."
        this.btnBlue = false
        let result = await this.$API.user.phoneRegisterLogin(phone, phoneCode);
        if (result.status == 0) {
          // this.doctorTop = result.data;
          this.activeName = 'second';
          this.$message.error(result.message);
          this.loginStatus = "登录/注册"
        } else if (result.status == 1) {
          this.$message({
            message: result.message,
            type: 'success'
          });
          this.isFram = false;
          this.btnBlue = false;
          this.$emit('phoneLoginSucc', { phoLogSucData: result.data });
          // this.$store.dispatch('user/getUserInfo') //将个人信息存入vuex
          // this.$store.commit('user/setUserInfo', result.data) 
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //倒计时
    countDown () {
      const timeCount = 60;
      if (!this.timer) {
        this.count = timeCount;
        this.isshow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= timeCount) {
            this.count--;
          } else {
            this.isshow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    //手机号获取验证码
    async asyncGetPhoneCode () {
      try {
        const { phone, operType } = this.confiForm;
        let result = await this.$API.user.getPhoneCode(phone, operType);
        this.isAsyncPhone = false;      //表示手机号发送成功
        this.phoneCodeTrue = false;     //验证码框可输入
        this.activeName = 'second';
        this.btnBlue = true;
        if (result.status == 1) {
          this.countDown();
          this.$message({
            message: result.message,
            type: 'success'
          });
        } else if (result.status == 0) {       //已发送过
          this.$message({
            message: result.message,
            type: 'warning'
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //验证码登录--登录/注册
    loginRegis: _.debounce(function (phoneParam, codeParam) {
      this.verifyInput(phoneParam);
      this.verifyInput(codeParam);
      const { phone } = this.confiForm;
      const { phoneCode } = this.verifyForm;
      if (phone && phoneCode) {
        this.btnBlue = true;
        this.asyncPhoneRegisterLogin();
      }
    }, 800),
    //账号登录
    accountLogin: _.debounce(function (param) {
      this.verifyInput(param);
      const { username, password } = this.form;
      if (username && password) {
        this.btnBlue = true;
        this.pasUserPasLogin();
      }
    }, 800),
    //忘记密码
    forgetPas () {
      this.$router.push('/account');
    },
    showMobile () {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      this.isMobile = isMobile ? true : false
    }
  }
}
</script>

<style scoped lang="less">
@import url("../assets/css/login.css");
.m-btn {
  width: 100%;
  height: 48px;
}
.m-s-login {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  padding: 20px 10px;
}
.m-lo-close {
  z-index: 9;
}
//以下是tabs样式
::v-deep .el-tabs__header{
  width: 54%;
  font-size: 16px;
}
::v-deep .tabLogin .el-tabs__header.is-top{
  margin: 0 auto 24px;
}
::v-deep .el-tabs__item{
  font-size: 16px;
  color: #999;
}
::v-deep .el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color:#fff;
  z-index: 1;
}
::v-deep .el-tabs__item.is-active,::v-deep .el-tabs__item:hover{
  color: #0066FF;
}
::v-deep .el-tabs__active-bar {
  background:#0066FF;
  height: 2px;
}
//以下是input样式
::v-deep .el-input__inner{
  border: 1px solid #E0E0E0;
  height: 48px;
  line-height: 48px;
}
::v-deep .el-input__inner:hover{
  border-color: #E0E0E0;
}
</style>