<template>
  <div class="headerBox">
    <!-- 整体盒子样式局中 -->
    <div v-if="isMobile" class="m-mobile">
      <!-- header部分 -->
      <router-link to="/home">
        <img class="m-logo" src="@/assets/img/common/logo.png" alt="">
      </router-link>
      <!-- 个人中心 -->
      <div class="m-info">
        <!-- 未登录 -->
        <span class="m-login" v-if="loginHide" @click="handleLogin">登录</span>
        <!-- 已登录 -->
        <div class="m-login-su" v-if="isShowPerCen">

          <div class="m-per-img">
            <!-- <img :src="showPerPicture" alt=""> -->
            <!-- 详细信息 -->
            <el-popover placement="bottom" width="245" trigger="hover" popper-class="popoverPadding">
              <img :src="userInfo.avatar" alt="" slot="reference">
              <div class="m-per-box">
                <div class="temp"></div>
                <div class="m-per-detail">
                  <p class="m-per-userName"><span class="m-score on">{{userInfo.username}}</span><span class="m-score"> 积分:{{userInfo.score}}</span></p>
                  <!-- <el-slider v-model="value1" :show-tooltip="false" disabled :percentage="userInfo.use_space/userInfo.all_space * 100"></el-slider> -->
                  <el-progress :show-text="false" :percentage="((userInfo.use_space/userInfo.all_space).toFixed(2)*100)"></el-progress>
                  <div class="m-per-slider">
                    <span>{{userInfo.use_space}}G/{{userInfo.all_space}}G</span>
                    <!-- <a href="#" @click="handelSpance">扩容</a> -->
                    <span class="expansion" @click="accountinfoUrl(3)">扩容</span>
                  </div>
                  <p class="m-per-friend">
                    🎁邀请好友获赠免费空间
                  </p>
                </div>
                <div class="m-per-nav-box">
                  <ul class="m-per-nav">
                    <li @click="personHome()">个人主页
                      <!-- <a href="#">个人主页</a> -->
                      <!-- <router-link to="/pc">个人主页</router-link> -->
                    </li>
                    <li @click="accountinfoUrl(1)">账户设置
                      <!-- <a href="#">账户设置</a> -->
                      <!-- <router-link to="/pc/accountinfo">账户设置</router-link> -->
                    </li>
                    <li @click="accountinfoUrl(2)">医师认证
                      <!-- <router-link to="/pc/accountinfo">医师认证</router-link> -->
                    </li>
                  </ul>
                </div>
                <div class="m-per-nav-box">
                  <ul class="m-per-nav">
                    <li @click="caseOperateUrl(1)">发布病例列表
                      <!-- <a href="#">发布病例列表</a> -->
                      <!-- <router-link to="/pc/caseoperate">发布病例列表</router-link> -->
                    </li>
                    <li @click="caseOperateUrl(2)">分享历史
                      <!-- <a href="#">分享历史</a> -->
                      <!-- <router-link to="/pc/caseoperate">分享历史</router-link> -->
                    </li>
                    <li @click="caseOperateUrl(3)">购买记录
                      <!-- <a href="#">购买记录</a> -->
                      <!-- <router-link to="/pc/caseoperate">购买记录</router-link> -->
                    </li>
                  </ul>
                </div>
              </div>
              <div class="m-per-exit" @click="exitLogin()">退出账号</div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="coreSize m-header">
      <!-- header部分 -->
      <router-link to="/home">
        <img class="m-logo" src="@/assets/img/common/logo.png" alt="">
      </router-link>
      <!-- 导航 -->
      <!-- <div class="m-nav" style="background:pink;">
        <ul> -->
      <!-- <li><router-link to="/home">首页</router-link></li> -->
      <!-- <li><router-link to="/database/caselist">微众数据库</router-link></li>-->
      <!-- <li><a href="">研究中心</a></li> -->
      <!-- <li><a href="">病例</a><router-link to="/database/caselist">病例</router-link></li> -->
      <!-- <li v-for="(item,index) in nav" :key="index" @click="headerNav(item.url,index)" :class="isActive == index ? 'active' : ''">{{item.name}}</li>
        </ul>
      </div> -->
      <!-- 新导航 -->
      <!-- ********************************************************************************************************************************************** -->
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <!-- :disabled="isMenuUrl" -->
        <el-menu-item index="1">首页</el-menu-item>
        <el-menu-item index="2" :disabled="isMenuUrl"> 微众数据库</el-menu-item>
        <el-menu-item index="3" :disabled="isMenuUrl"> 病例</el-menu-item>
        <el-menu-item index="4" :disabled="isMenuUrl"> 三维重建</el-menu-item>
        <!-- <el-menu-item index="3" disabled>消息中心</el-menu-item>
        <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
      </el-menu>
      <!-- ********************************************************************************************************************************************** -->
      <!-- 搜索 -->
      <div class="m-search">
        <!-- <div class="m-history">
          <p class="m-his-title">近期搜索</p>
          <ul class="m-his-ul">
              <li>
                  <a href="#">胶质瘤</a>      
              </li>
              <li>
                  <a href="#">华山</a>      
              </li>
              <li>
                  <a href="#">血肿</a>      
              </li>
              <li>
                  <a href="#">血肿</a>      
              </li>
          </ul>  
        </div> -->
        <el-popover placement="bottom" width="246" trigger="click" id="selPop" v-if="searHistory.length > 0">
          <div>
            <!-- <span>近期搜索</span> -->
            <p class="m-his-title">
              近期搜索
              <span @click="clearHistory()">清除</span>
            </p>
            <ul class="m-his-ul">
              <li v-for="(list,index) in searHistory" :key="index" @click="clickHistoryVal(list)">{{ list }}</li>
            </ul>
          </div>
          <div class="m-se-text" slot="reference">
            <!-- @keyup.enter="searchInfo()" -->
            <input type="text" v-model="serVal" placeholder="输入关键词搜索">
            <i class="el-icon-search m-search-icon" @click="searchInfo()"></i>
          </div>
        </el-popover>
        <div v-else class="m-se-text">
          <input type="text" v-model="serVal" placeholder="输入关键词搜索">
          <i class="el-icon-search m-search-icon" @click="searchInfo()"></i>
        </div>
      </div>
      <!-- 公告 -->
      <!-- <div class="m-notice" style="background:pink;">
        <el-carousel height="40" direction="vertical" :autoplay="true" indicator-position="none">
          <el-carousel-item v-for="(list) in mesList" :key="list.ann_id">
            <span @click="mesUrl()">{{ list.ann_text }}</span>
          </el-carousel-item>
        </el-carousel>
      </div> -->

      <!-- 个人中心 -->
      <div class="m-person" style="width:140px">
        <!-- 未登录 -->
        <span class="m-login" v-if="loginHide" @click="handleLogin">登录</span>
        <!-- 已登录 -->
        <div class="m-login-su" v-if="isShowPerCen">
          <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& -->
          <!--新通知-->
          <div class="m-noti-tips">
            <el-popover placement="bottom" width="384" trigger="hover">
              <div class="m-head-popo">
                <!-- 全部已读 -->
                <p class="isRead" @click="allRead()">全部已读</p>
                <el-tabs v-model="activeName" @tab-click="handleClick">
                  <!-- 私信 -->
                  <el-tab-pane name="first">
                    <!-- 小圆点 -->
                    <span slot="label" class="m-tabs-title">私信<i class="isHave" v-if="isChatTag"></i></span>
                    <div v-if="privateLetterList.length > 0">
                      <!-- 循环体 -->
                      <div class="m-head-ri-con">
                        <!-- 1 -->
                        <div class="m-head-noti-loop" v-for="(list,index) in privateLetterList" :key="index"
                          @click="privateLetterUrlNew('2',userInfo.id,list.from)">
                          <div class="m-head-my-photo">
                            <img :src="list.avatar" alt="头像">
                            <!-- 1:已读  0 未读 -->
                            <span class="m-head-is-read" v-if="list.state =='0'"></span>
                          </div>
                          <div class="m-head-loop-info">
                            <p>{{ list.username }}</p>
                            <div class="m-head-loop-con">
                              <span>{{ list.content }}</span>
                              <span>12:30</span>
                            </div>
                          </div>
                        </div>
                        <!-- 2 -->
                        <!-- <div class="m-head-noti-loop" v-for="(list,index) in noticeList" :key="index">
                          <img class="m-head-my-photo" :src="list.avatar" alt="头像">
                          <div class="m-head-loop-info">
                            <p>{{ list.username }}<span>{{noticeType[Number(list.type)-1 ]}}了</span></p>
                            <div class="m-head-loop-con" @click="detailPageUrl(list.article_id)">《{{ list.article_title }}》</div>
                          </div>
                        </div> -->
                      </div>
                      <!-- 按钮 -->
                      <p class="m-head-tab-btn" @click="privateLetterUrlNew('2',userInfo.id,privateLetterList[0].from)">查看全部私信<i
                          class="el-icon-arrow-right"></i></p>
                    </div>
                    <div v-else class="m-noti-no-data">
                      <img class="" src="@/assets/img/common/noticeData.png" alt="">
                      <p>暂无私信</p>
                    </div>
                    <!-- <el-empty v-else :image-size="80" description="暂无通知"></el-empty> -->
                  </el-tab-pane>
                  <!-- 动态 -->
                  <el-tab-pane name="second">
                    <!-- 小圆点 -->
                    <span slot="label" class="m-tabs-title">动态<i class="isHave" v-if="isNoticeTag"></i></span>
                    <div v-if="noticeList.length > 0">
                      <!-- 循环体 -->
                      <div class="m-head-ri-con">
                        <!-- 1 -->
                        <!-- <div class="m-head-noti-loop">
                          <img class="m-head-my-photo" src="@/assets/img/common/example.png" alt="">
                          <div class="m-head-loop-info">
                            <p>我是名字<span>私信了</span></p>
                            <div class="m-head-loop-con on">2天前</div>
                          </div>
                        </div> -->
                        <!-- @click="haveReadNotice(list.notice_id)" -->
                        <div class="m-head-noti-loop" v-for="(list,index) in noticeList" :key="index" @click="detailPageUrl(list.article_id,list.notice_id)">
                          <div class="m-head-my-photo">
                            <img :src="list.avatar" :alt="list.ann_title">
                            <span class="m-head-is-read" v-if="list.state =='0'"></span>
                          </div>
                          <div class="m-head-loop-info">
                            <div>{{ list.username }}
                              <span v-if="list.type">{{noticeType[Number(list.type)-1]}}了</span>
                              <label v-if="list.article_title" class="m-head-loop-con oy">《{{ list.article_title }}》</label>
                            </div>
                            <div class="m-head-loop-con on">{{list.created_at}}</div>
                          </div>
                        </div>
                        <!-- <div class="m-head-noti-loop" v-for="(list,index) in noticeList" :key="index">
                          <img class="m-head-my-photo" :src="list.avatar" alt="头像">
                          <div class="m-head-loop-info">
                            <p>{{ list.username }}<span>{{noticeType[Number(list.type)-1 ]}}了</span></p>
                            <div class="m-head-loop-con" @click="detailPageUrl(list.article_id)">《{{ list.article_title }}》</div>
                          </div>
                        </div> -->
                      </div>
                      <!-- 按钮 -->
                      <p class="m-head-tab-btn" @click="noticeAllUrl('1')">查看全部动态<i class="el-icon-arrow-right"></i></p>
                    </div>
                    <div v-else class="m-noti-no-data">
                      <img class="" src="@/assets/img/common/noticeData.png" alt="">
                      <p>暂无动态</p>
                    </div>
                    <!-- <el-empty v-else :image-size="80" description="暂无私信"></el-empty> -->
                  </el-tab-pane>
                  <!-- 公告 -->
                  <el-tab-pane name="three">
                    <!-- 小圆点 -->
                    <span slot="label" class="m-tabs-title">公告<i class="isHave" v-if="isannTag"></i></span>
                    <div v-if="mesList.length > 0">
                      <!-- 循环体 -->
                      <div class="m-head-ri-con">
                        <!-- 1 -->
                        <!-- <div class="m-head-noti-loop">
                          <img class="m-head-my-photo" src="@/assets/img/common/systemIcon.png" alt="">
                          <div class="m-head-loop-info">
                            <p>我是名字</p>
                            <div class="m-head-loop-con">
                              <span>系统消息通知公告系统消息通知公告系统消息</span>
                              <span>12:30</span>
                            </div>
                          </div>
                        </div> -->
                        <div class="m-head-noti-loop" v-for="(list) in mesList" :key="list.ann_id" @click="annUrl('3')">
                          <div class="m-head-my-photo">
                            <img :src="list.avatar" :alt="list.ann_title">
                            <span class="m-head-is-read" v-if="list.state =='0'"></span>
                          </div>
                          <div class="m-head-loop-info">
                            <p>{{list.username}}</p>
                            <div class="m-head-loop-con">
                              <span>{{ list.ann_text }}</span>
                              <span>{{list.created_at}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 按钮 -->
                      <p class="m-head-tab-btn" @click="annUrl('3')">查看全部公告<i class="el-icon-arrow-right"></i></p>
                    </div>
                    <div v-else class="m-noti-no-data">
                      <img class="" src="@/assets/img/common/noticeData.png" alt="">
                      <p>暂无公告</p>
                    </div>
                    <!-- <el-empty v-else :image-size="80" description="暂无公告"></el-empty> -->
                  </el-tab-pane>
                </el-tabs>
              </div>
              <div slot="reference" class="m-heade-notice">
                <i class="el-icon-bell"></i>
                <span v-if="isNoticeTag || isannTag || isChatTag"></span>
              </div>
            </el-popover>
          </div>
          <!-- &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& -->
          <!-- 个人中心【个人中心】 -->
          <div class="m-per-img">
            <!-- <img :src="showPerPicture" alt=""> -->
            <!-- 详细信息 -->
            <el-popover placement="bottom" width="245" trigger="click" popper-class="popoverPadding">
              <img :src="userInfo.avatar" alt="头像" slot="reference">
              <div class="m-per-box">
                <div class="temp"></div>
                <div class="m-per-detail">
                  <!-- <p class="m-per-userName"><span class="m-score on">{{userInfo.username}}</span><span class="m-score"> 积分:{{userInfo.score}}</span></p> -->
                  <div class="m-per-userName">
                    <img class="p-center-img" :src="userInfo.avatar" alt="头像" />
                    <p class="p-center-name">{{userInfo.username}}</p>
                  </div>
                  <!-- <el-slider v-model="value1" :show-tooltip="false" disabled :percentage="userInfo.use_space/userInfo.all_space * 100"></el-slider> -->
                  <el-progress :show-text="false" :percentage="((userInfo.use_space/userInfo.all_space).toFixed(2)*100)"></el-progress>
                  <div class="m-per-slider">
                    <span>{{userInfo.use_space}}G/{{userInfo.all_space}}G</span>
                    <!-- <a href="#" @click="handelSpance">扩容</a> -->
                    <span class="expansion" @click="accountinfoUrl(3)">扩容</span>
                  </div>
                  <p class="m-per-friend">
                    🎁邀请好友获赠免费空间
                  </p>
                </div>
                <div class="m-per-nav-box">
                  <ul class="m-per-nav">
                    <li @click="personHome()">个人主页
                      <!-- <a href="#">个人主页</a> -->
                      <!-- <router-link to="/pc">个人主页</router-link> -->
                    </li>
                    <li @click="accountinfoUrl(1)">账户设置
                      <!-- <a href="#">账户设置</a> -->
                      <!-- <router-link to="/pc/accountinfo">账户设置</router-link> -->
                    </li>
                    <li @click="accountinfoUrl(2)">医师认证
                      <!-- <router-link to="/pc/accountinfo">医师认证</router-link> -->
                    </li>
                  </ul>
                </div>
                <div class="m-per-nav-box">
                  <ul class="m-per-nav">
                    <li @click="caseOperateUrl(1)">发布病例列表
                      <!-- <a href="#">发布病例列表</a> -->
                      <!-- <router-link to="/pc/caseoperate">发布病例列表</router-link> -->
                    </li>
                    <li @click="caseOperateUrl(2)">分享历史
                      <!-- <a href="#">分享历史</a> -->
                      <!-- <router-link to="/pc/caseoperate">分享历史</router-link> -->
                    </li>
                    <li @click="caseOperateUrl(3)">购买记录
                      <!-- <a href="#">购买记录</a> -->
                      <!-- <router-link to="/pc/caseoperate">购买记录</router-link> -->
                    </li>
                  </ul>
                </div>
              </div>
              <div class="m-per-exit" @click="exitLogin()">退出账号</div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>

    <!-- 登录组件 v-show="isLogin"-->
    <Login v-show="isLogin" :isShowLogo="isLogin" @parLogin="getChilLogin" @phoneLoginSucc="getPhoneLoginSucc" @accountLoginSucc='getAccountLoginSucc' />
    <!-- 去设置弹框 title="提示"-->
    <el-dialog :visible.sync="setDialog" :show-close="false" width="38%" center :close-on-click-modal="false">
      <img src="@/assets/img/common/loginBg.png" class="promptHeadBg" alt="提示背景图">
      <ul class="promptInfo">
        <li>尊敬的用户，感谢您使用普视云！</li>
        <li class="on">为了更好地为您提供服务，需要您完善以下用户信息</li>
        <li>1.用户名&昵称</li>
        <li>2.电子邮箱</li>
        <li>3.登录密码</li>
      </ul>
      <div class="promptImporBox">
        <div class="promptImport">
          <p>我们非常重视您的隐私保护，您提供的所有信息将被严格保密，不会泄露给任何第三方。同时我们将确保您的信息安全，防止信息被盗用或滥用。</p>
          <p>感谢您的配合和支持！如有任何问题或需要帮助，请随时联系我们的客服。</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDialog = false" style="margin-right:30px;">取消</el-button>
        <el-button type="primary" @click="setUserAccountInfo()">去设置</el-button>
      </span>
    </el-dialog>
  </div>
</template> 

<script>
import { mapState, mapMutations } from "vuex"
import Login from '@/components/login'
export default {
  name: 'Header',
  data () {
    return {
      loginHide: true,         //登录隐藏
      isShowPerCen: false,  //是否显示个人中心
      value1: 50,
      activeName: 'first',
      nav: [               //头部菜单栏
        {
          name: '首页',
          url: '/home'
        },
        {
          name: '微众数据库',
          url: '/database/caselist'
        },
        {
          name: '病例',
          url: '/case'
        },
        {
          name: '三维重建',
          url: '/reconstruction'
        }
      ],
      isActive: 0,
      currentPage: 1,      //当前页
      mesList: [],         //系统消息列表
      serVal: '',          //搜索值
      searHistory: [],     //搜索历史值
      // isLogin: false,       //是否登录
      setDialog: false,     //去设置对话框 
      showPerPicture: '@/assets/img/common/logo.png',              //显示个人头像
      perName: '设置名字',
      getLoginDialogShow: localStorage.getItem('loginDialogShow'),  //是否显示Login框
      t_list: 1,	        //1为首页消息滚动  2为消息总列表
      collectList: [],    //通知收藏列表
      noticeList: [],          //通知
      privateLetterList: [],   //私信列表
      getUserId: '',
      noticeType: ['点赞', '收藏', '评论', '关注'],
      isMobile: false,
      isNoticeTag: false,      //动态签标记
      isannTag: false,        //公告签标记
      isChatTag: false,        //私信签标记
      activeIndex: '1',       //导航控制
      isMenuUrl: false,        //是否导航跳转
    }
  },
  components: {
    Login
  },
  computed: {
    // 将 store 映射到当前组件的计算属性
    ...mapState('user', ['isLogin']),
    ...mapState('user', ['userInfo'])
  },
  created () {
    this.getCollectInfo()
  },
  watch: {
    '$route.path': function (newVal, oldVal) {
      // console.log('newVal,oldVal-----------------', newVal, oldVal);
      const whiteList = ['/', '/home', '/case', '/search']
      const token = this.$localStorage.getToken() && this.$localStorage.getToken().access_token
      if (!token && !whiteList.includes(newVal)) {
        // this.$message.error('无权限,请先登录!')
        return this.handleLogin()
      }
      if (newVal !== '/search') {
        this.serVal = ''
      }
      if (newVal == '/caserecord') {
        this.activeIndex = '3'
      }
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (to.query.id !== from.query.id) {
      next();
      this.$router.push(
        {
          path: `/pc/pershomepage`,
          query: { id: to.query.id }
        }
      )
      this.$router.go(0);
    }
  },
  mounted () {
    this.headerSystemMesList();
    // this.searHistory = this.$storage.get('searchList').slice(0,10)        //获取历史记录

    // console.log('获历史记录',this.searHistory);
    this.arrayReverse();
    // console.log('fdkfjdfkjdfkd', localStorage.getItem('loginDialogShow'));
    if (this.$localStorage.getToken() && this.$localStorage.getToken().access_token) {          //有Token
      this.isShowPerCen = true;
      this.loginHide = false;
      // const { avatar, username } = this.userInfo;
      // this.showPerPicture = avatar;
      // this.perName = username;
    } else if (this.getLoginDialogShow) {
      this.handleLogin()
      localStorage.removeItem('loginDialogShow');
    }
    else {
      this.isShowPerCen = false;              //无Token
      this.loginHide = true;
    }

    this.noticeListNumsFun();
    // this.getUserId = this.$localStorage.getToken() && this.$localStorage.getToken().id ? this.$localStorage.getToken().id : '';
    this.getUserId = this.$localStorage.getToken() && this.userInfo.id;
    this.showMobile()
  },
  methods: {
    //导航跳转
    handleSelect (key, keyPath) {
      console.log(this.$localStorage.getToken(), key, keyPath, '----')
      const token = this.$localStorage.getToken() && this.$localStorage.getToken().access_token
      switch (key) {
        case '1': this.$router.push({ path: '/home' })
          break;
        case '2':
          token ? this.$router.push({ path: '/database/caselist' }) : this.handleLogin()
          break;
        case '3': this.$router.push({ path: '/case' })
          break;
        case '4':
          token ? this.$router.push({ path: '/reconstruction' }) : this.handleLogin()
          break;
      }
      // if (!this.$localStorage.getToken() && !this.$localStorage.getToken().access_token) {
      //   this.isMenuUrl = true;
      // }
    },
    // 点击登录 给islogin变为true
    handleLogin () {
      this.$store.commit('user/setIsLogin', true)
    },
    handleClick (tab, event) {
      console.log('tab切换', tab, event);
    },
    //头部url跳转
    headerNav (url, index) {
      if (this.$localStorage.getToken() && this.$localStorage.getToken().access_token) {
        this.$router.push({
          path: url
        })
        this.isActive = index
      } else {
        if (url != '/home') return this.handleLogin()
        this.$router.push({
          path: url
        })
        this.isActive = index
      }

    },
    //头部--系统消息列表
    async headerSystemMesList () {
      try {
        let result = await this.$API.user.systemMesList(this.currentPage, this.t_list);
        if (result.status == 1) {
          this.mesList = result.data.map((list) => {    //系统数据
            list.created_at = this.$moment.unix(list.created_at).format('mm:ss');
            list.state == '0' ? this.isannTag = true : this.isannTag = false;      //公告签标记
            return list;
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //通知全部跳转
    noticeAllUrl (activeName,) {
      //公告
      let ids = this.getNoticeId();
      this.haveReadNotice(ids)
      this.$router.push({ path: '/pc/accountinfonotice', query: { activeName } })
    },
    //公告单独每条/全部跳转
    annUrl (activeName, id) {
      let ann_ids = this.getAnnId();
      this.haveReadAnn(ann_ids);
      this.$router.push({ path: '/pc/accountinfonotice', query: { activeName } });
    },
    //数组的倒序输出
    arrayReverse () {
      let getSearchList = this.$storage.get('searchList');
      if (getSearchList && getSearchList.length > 0) {
        const getHisRecord = JSON.parse(this.$aes.decrypt(this.$storage.get('searchList')));
        for (var i = getHisRecord.length - 1; i >= 0; i--) {
          this.searHistory.push(getHisRecord.pop());
        }
        this.searHistory = this.searHistory.slice(0, 10);
      }
    },
    //点击搜索
    searchInfo () {
      const currentSearchVal = this.serVal.trim();
      if (currentSearchVal) {
        this.searHistory.push(currentSearchVal);
        const localKey = this.$aes.encrypt(this.searHistory);
        // const currentSearVal = this.$aes.encrypt(this.currentSearchVal);
        // this.$storage.set('currentSearKey',currentSearVal);           //当前搜索值
        this.$storage.set('searchList', localKey);                     //当前搜索历史
        //跳转路由
        this.$router.push({
          path: '/search',
          query: {
            search: currentSearchVal,
            page: 1,
            random: Date.parse(new Date()),
          }
        })
      } else {
        this.$message({
          message: '请输入关键词搜索',
          type: 'warning',
          duration: 1000
        });
      }
    },
    //点击清空历史
    clearHistory () {
      this.searHistory = [];
      this.$storage.set('searchList', this.searHistory);
    },
    //获取Login关闭值
    getChilLogin ({ loginClose }) {
      // this.isLogin = loginClose;
      this.$store.commit('user/setIsLogin', loginClose)
    },
    //登录设置公共参数
    async publicLoginParam (param) {
      if (Object.keys(param).length > 0) {
        this.$localStorage.setToken(param);   //设置token
        this.isShowPerCen = true;
        this.loginHide = false;
        await this.$store.dispatch('user/getUserInfo')
        //登录成功后刷新当前页面
        this.isMobile ? window.location.reload() : this.$router.go(0)

        // const { avatar, username } = param;
        // this.showPerPicture = avatar;
        // this.perName = username;
      } else {
        this.$localStorage.clearToken();
        this.isShowPerCen = false;
        this.loginHide = true;
      }
    },
    //获取手机号登录成功的数据
    getPhoneLoginSucc ({ phoLogSucData }) {
      this.$store.commit('user/setIsLogin', false)

      this.publicLoginParam(phoLogSucData);
      if (!phoLogSucData.password_hash) {
        this.setDialog = true;
      }
    },
    //设置个人账号信息
    setUserAccountInfo () {
      this.$router.push({ path: '/pc/accountinfo' })
      this.setDialog = false
    },
    //获取账号登录成功信息
    getAccountLoginSucc ({ accLogSucData }) {
      // this.isLogin = false
      this.$store.commit('user/setIsLogin', false)
      this.publicLoginParam(accLogSucData);
    },
    //退出账号
    exitLogin () {
      this.$localStorage.removeToken();   //清除token
      this.$router.push({
        path: '/'
      })
      this.$router.go(0)
    },
    //发布病例列表、分享历史、购买记录
    caseOperateUrl (urlParam) {
      const { userInfo } = this
      // this.$router.push({
      //   path: '/pc/caseoperate',
      //   query: {
      //     user_id: userInfo.id,
      //     // user_id: '2010',
      //     activeName: urlParam.toString(),
      //   }
      // })
      this.$router.push({
        path: '/caserecord',
        query: {
          user_id: userInfo.id,
          // user_id: '2010',
          activeName: urlParam.toString(),
        }
      })
    },
    //点击历史值
    clickHistoryVal (searchVal) {
      this.serVal = searchVal;
      this.$router.push({
        path: '/search',
        query: {
          search: searchVal,
          page: 1,
          random: Date.parse(new Date()),
        }
      })
    },
    // 获取通知 收藏的列表
    async getCollectInfo () {
      const res = await this.$API.per.noticeList(2, 1)
      if (res.code == 200 && res.status == 1) {
        this.collectList = res.data.model.filter((item, index) => {
          return index < 2
        })
      }
    },
    //获取通知消息数量/首页导航栏
    async noticeListNumsFun () {
      try {
        let result = await this.$API.hom.noticeListNums();
        console.log('获取通知消息数量/首页导航栏 ====================>', result);
        if (result.status == 1) {
          const { chat, notify } = result.data;
          this.noticeList = notify.map((list) => {    //动态数据
            list.created_at = this.$moment.unix(list.created_at).fromNow();
            list.state == '0' ? this.isNoticeTag = true : this.isNoticeTag = false;     //通知签标记  1-已读   0-未读
            return list;
          });;         //通知
          this.privateLetterList = chat.map((list) => { //私信列表
            list.state == '0' ? this.isChatTag = true : this.isChatTag = false;     //通知签标记 1-已读   0-未读
            return list
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //跳转到病例详情页
    detailPageUrl (id, noticeId) {
      this.haveReadNotice(noticeId);          //动态已读
      this.$router.push({
        path: '/casedetails',
        name: 'CaseDetails',
        query: {
          id
        }
      })
    },
    //跳转至私信页(旧)
    privateLetterUrl (activeName, user_id, from) {
      const query = {
        to: user_id,
        activeName: activeName
      }
      from ? query.from = from : query;
      this.$router.push(
        {
          path: `/pc/mesnoticy`,
          query
        }
      )
    },
    //跳转至私信页(新)
    privateLetterUrlNew (activeName, user_id, from) {
      const query = {
        to: user_id,
        activeName: activeName
      }
      from ? query.from = from : query;
      this.$router.push(
        {
          path: `/pc/mesnoticy`,
          query
        }
      )
    },
    //跳转个人主页
    personHome () {
      this.$router.push(
        {
          path: `/pc`,
        }
      )
    },
    //跳转至认证信息、空间容量
    accountinfoUrl (activeName) {
      const { userInfo } = this
      this.$router.push(
        {
          path: `/pc/accountinfo`,
          query: {
            user_id: userInfo.id,
            activeName: activeName
          }
        }
      )
    },
    //手机端显示验证
    showMobile () {
      var ua = navigator.userAgent;
      var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      this.isMobile = isMobile ? true : false
    },
    //动态ID获取
    getNoticeId () {
      let ids = this.noticeList.map((list) => {
        return list.notice_id
      })
      return ids
    },
    //公告ID获取
    getAnnId () {
      let ann_ids = this.mesList.map((list) => {
        return list.ann_id
      })
      return ann_ids
    },
    //全部已读
    async allRead () {
      try {
        let readParam = {
          ids: this.getNoticeId(),
          ann_ids: this.getAnnId()
        }
        console.log('readParam--------------->', readParam);
        let result = await this.$API.hom.haveReadAll(readParam);
        if (result.status == 1) {
          this.headerSystemMesList();
          this.noticeListNumsFun();
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //公告已读
    async haveReadAnn (ann_ids) {
      try {
        let result = await this.$API.hom.haveReadAnn({ ann_ids });
        if (result.status == 1) {
          this.headerSystemMesList();
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    //动态已读
    async haveReadNotice (ids) {
      let idsV = []
      idsV.push(ids);
      try {
        let result = await this.$API.hom.haveReadNotice({ ids: idsV });
        if (result.status == 1) {
          this.noticeListNumsFun();
        }
      } catch (error) {
        console.log('error', error);
      }
    },
  }
}
</script>

<style scoped lang="less">
@import url("@/assets/css/header.css");

/*修改导航*/
::v-deep .el-menu {
  width: 42%;
  margin-left: 52px;
}
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal > .el-menu-item {
  height: auto;
  line-height: normal;
  margin: 0 16px;
  padding: 0;
  font-size: 16px;
  color: #333333;
}
::v-deep .el-menu--horizontal > .el-menu-item.is-active {
  color: #0066ff;
  border-bottom: 2px solid #0066ff;
}
.el-menu--horizontal > .el-menu-item {
  display: inline-block;
  padding: 9px 8px;
}
.m-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 15px;
}
.m-score {
  font-size: 12px;
  color: #999;
  padding-bottom: 8px;
}
.m-score.on {
  font-size: 14px;
  color: #333333;
  display: inline-block;
  width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .el-slider__runway {
  margin: 0;
  margin-top: 20px;
}
::v-deep .el-slider__button-wrapper {
  display: none;
}
::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #409eff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
::v-deep .el-carousel__container {
  height: 40px;
}
::v-deep .el-carousel__item span {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 40px;
  margin: 0;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

// 私信通知
::v-deep .el-popover {
  padding: 0 !important;
  margin-top: 0 !important;
}
.m-head-popo {
  height: auto;
  // margin: -12px;
  padding: 0 8px;
  overflow: hidden;
  position: relative;
}
/* 
// 为了不影像登录样式，先注掉
::v-deep .el-tabs__nav {
  width: 30%;
  padding: 10px 0;
  float: none;
  margin: 0 auto;
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
}
::v-deep .el-tabs__item {
  width: auto;
  text-align: center;
  border-right: 1px solid #f1f1ef;
  height: auto;
  line-height: normal;
  padding: 0 18%;
}
::v-deep .el-tabs__item:last-child {
  border: none;
}
::v-deep .el-tabs__nav-wrap {
  margin-bottom: 10px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  margin-bottom: 1px;
}
*/
::v-deep .el-tabs__header.is-top {
  margin: 0;
}
::v-deep .el-empty {
  padding: 0 0 20px;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0;
}
::v-deep .el-dialog--center .el-dialog__footer {
  text-align: right;
  padding: 10px 30px 20px;
}
</style>
