import request from '@/utils/request'

//搜索用户--get请求带参
export const searchUser = (search) => request({url:`/v1/front/search_user/${search}`,method: 'get'})
// 病例推荐---get不带参请求
export const caseTop = () => request({url:`/v1/front/case_top`,method: 'get'})

//post请求---带参
export const login = (loginInfo) => request({url:`/v1/login/login`,method:'post',data:loginInfo})

//post请求---不带参案例
export const  loginPost = () => request({url:`/v1/login/login`,method:'post'})


/*以上是请求例子 到时候会删除*/
// 【用户公告模块】
//用户协议
export const userAgreement = (page) => request({url:`/v1/about-web/user_agreement`,method: 'get'})

//隐私政策
export const privacyPolicy = (page) => request({url:`/v1/about-web/privacy_policy`,method: 'get'})

//关于我们
export const aboutUs = (page) => request({url:`/v1/about-web/about_us`,method: 'get'})

//关扩容说明
export const capacityInfo = (page) => request({url:`/v1/about-web/expansion_instructions`,method: 'get'})

//联系我们
export const contactUs = (page) => request({url:`/v1/about-web/contact_us`,method: 'get'})

//更多产品
export const moreProducts = (page) => request({url:`/v1/about-web/more_products`,method: 'get'})


//【个人主页】
//其他或者自己用户详情
export const userInfo = (userId) => request({url:`/v1/user/user_info/${userId}`,method: 'get'})

//是否显示公开个人信息
export const isPublicPerInfo = (perInfo) => request({url:`/v1/user/user_change_show_msg`,method: 'post',data:perInfo})

//其他或者自己收藏夹
export const  userCollection= (user_id,page) => request({url:`/v1/user/user_collection?user_id=${user_id}&page=${page}`,method: 'get'})

//其他或者我的关注[我的关注]
export const userFollow = (user_id,page) => request({url:`/v1/user/user_follow?user_id=${user_id}&page=${page}`,method:'get'})

//其他或者我的粉丝[关注我的]
export const followMe = (user_id,page) => request({url:`/v1/user/user_follow_to?user_id=${user_id}&page=${page}`,method:'get'});

//用户关注
export const goFocusOn = (followed_id) => request({url:`/v1/follow/go_focus_on`,method:'post',data:followed_id})

//用户取消关注
export const cancleFocusOn = (followed_id) => request({url:`/v1/follow/cancel_focus_on`,method:'post',data:followed_id})

//公开病例
export const articlePublic = (page) => request({url:`/v1/user/user_article_public_list?page=${page}`,method:'get'})

//删除病例
export const deleteCase = (article_id) => request({url:`/v1/article/article_public_release_del`,method:'post',data:article_id})

//文章分享内容展示
export const shareHistory = (page)=> request({url:`v1/db-share/user_private_url_list?page=${page}`,method:'get'})

//分享历史【取消分享】
export const priCancleShare = (share_id)=> request({url:`/v1/db-share/private_url_del`,method:'post',data:share_id})

//购买记录
export const purchaseRecord = (page)=> request({url:`/v1/user/order_all_list?page=${page}`,method:'get'})

//系统列表 1为首页消息滚动  2为消息总列表
export const announcementList = (page,t_list)=> request({url:`/v1/chat/announcement_list?page=${page}&t_list=${t_list}`,method:'get'})

//通知列表
export const noticeList = (type,page)=> request({url:`/v1/chat/notice_list?type=${type}&page=${page}`,method:'get'})

//获取个人资料信息
export const individualInfoShow = ()=> request({url:`/v1/user/user_info_show`,method:'get'})

//用户头像上传
export const userAvatarFile = (file)=> request({url:`/v1/user/user_avatar_file`,method:'post',data:file})

//个人资料更新 (关于,头像,昵称,邮箱,签名)
export const userDataUpdate = (sendData)=> request({url:`/v1/user/user_data_update`,method:'post',data:sendData})

//认证信息更新/add  
export const userInfoUpdate = (saveData)=> request({url:`/v1/user/user_info_update`,method:'post',data:saveData})

//城市查询
export const areaGo = ()=> request({url:`/v1/user/area_go`,method:'get'})

//医院查询  
export const hospitalGo = (cities,page)=> request({url:`v1/user/hospital_go?cities=${cities}&page=${page}`,method:'get'})

//学生证上传
export const userStudentCardUpload = (file)=> request({url:`/v1/user/user_student_card_upload`,method:'post',data:file})

//科室类型   
export const department = ()=> request({url:`/v1/branch-class/department`,method:'get'})

//文章病例购买记录
export const orderAllList = (page)=> request({url:`/v1/user/order_all_list?page=${page}`,method:'get'})

//用户空间明细
export const spaceDetails = (page)=> request({url:`/v1/space/space_details?page=${page}`,method:'get'})

//用户空间空间容量and空间订单 
export const userSpace = (page)=> request({url:`/v1/space/user_space?page=${page}`,method:'get'})

//用户微信购买(空间)
export const wechatSpace = (param)=> request({url:`/v1/pay/wechat_space`,method:'post',data:param})

//用户微信支付异步回调(空间)
export const wechatNotify = (page)=> request({url:`/v1/pay/wechat_notify`,method:'get'})

//用户微信支付查询订单(空间) v1/pay/wechat_order_space
export const wechatOrder  = (out_trade_no)=> request({url:`/v1/pay/wechat_order?out_trade_no=${out_trade_no}`,method:'get'})
// export const wechatOrder  = (out_trade_no)=> request({url:`v1/pay/wechat_order_space?out_trade_no=${out_trade_no}`,method:'get'})

//私信列表
export const chatList = (page)=> request({url:`/v1/chat/chat_list?page=${page}`,method:'get'})

//私信添加
export const chatAdd = (to)=> request({url:`v1/chat/chat_add`,method:'post',data:to})

//修改手机号或者邮箱  
export const reviseValue = (param)=> request({url:`/v1/user/revise_value`,method:'post',data:param})

