import * as db from '@/api/DataBase/dataBase'
import * as per from '@/api/perCenter/perCenter'
import * as hom from '@/api/Home/home'
import * as user from '@/api/User/user'

//对外暴露--->挂载vue原型
export default {
    db,
    per,
    hom,
    user
}
