import request from '@/utils/request'

//头部--系统消息列表
export const systemMesList = (page,t_list) => request({url:`/v1/chat/announcement_list?page=${page}&t_list=${t_list}`,method: 'get'})

//头部--用户名+密码登录
export const userPasLogin = (loginInfo) => request({url:`/v1/login/login`, method: 'post',data:loginInfo})

//头部--手机验证码 登录/注册
export const phoneRegisterLogin = (phone,code) => request({url:`/v1/login/login_phone?phone=${phone}&code=${code}`,method: 'get'})

//头部--手机号获取验证码
//type { 1:短信登录 2:修改密码 }
export const getPhoneCode = (phone,type) => request({url:`/v1/login/verify?phone=${phone}&t=${type}`,method: 'get'})

//修改密码
export const editPassword = (editPassForm) => request({ url: `/v1/login/change_password`, method: 'post', data: editPassForm })

//获取个人信息
export const getUserInfo = () => request({ url: `/v1/login/token_user`, method: 'get' })