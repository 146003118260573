import request from '@/utils/request'
// 病例列表
export const getcaseList = (num) => request({ url: `v1/db/data_list?page=${num}`, method: 'get',  })
//获取病种类型
export const getcaseType = (id) => request({ url: `v1/branch-class/diseases/${id}`, method: 'get',  })
//删除病例
export const putcase= (id) => request({ url: `v1/db/delete/${id}`, method: 'put',  })
// 编辑病例详情
export const getcaseDetail = (id) => request({ url: `v1/db/command/${id}`, method: 'get',  })

//提交病例
export const submitCase = (data) => request({ url: `v1/db/install`, method: 'post', data,  })
//更新病例
export const updateCase = (id, data) => request({ url: `v1/db/update/${id}`, method: 'post', data,  })
//发布病例 
export const publishCase = (data) => request({ url: `v1/article/article_public_release`, method: 'post', data,  })
//分享病例
export const sharehCase = (data) => request({ url: `v1/db-share/private_url_create`, method: 'post', data,  })
// 查看分享病例
export const looksharehCase = (id) => request({ url: `/v1/db-share/private_url_show/${id}`, method: 'get',  })
// 查看分享病例
export const showsharehCase = (id) => request({ url: `/v1/article/public_release_json/${id}`, method: 'get' })
//判断空间是否足够
export const getSpace = (num) => request({ url: `v1/space/is_space_enough?size=${num}`, method: 'get',  })

//获取病例添加曲线图数据
export const getCaseOverview = (startTime,endTime) => request({ url: `v1/db/graph_show_add_case?start_time=${startTime}&end_time=${endTime}`, method: 'get' })


//ct上传
export const uploadCtCase = (data) => request({ method: 'post', url: '/v1/db/upload_ct', data })
// 病例发布图像上传
export const publishAvator = (data) => request({ method: 'post', url: 'v1/article/article_public_image', data })

//获取已发布病例详情
export const getPublishDetail = (id) => request({ url: `/v1/article/article_public_release_details/${id}`, method: 'get', })
// 更新发布的病例
export const updatePublishCase = (data) => request({ url: `/v1/article/article_public_release_update`, method: 'post',data  })
