//浏览器存储加密、解密
import CryptoJS from 'crypto-js'
const passKey = {
    /**
     * CryptoJS 加密
     *
     * @param {String} entData  需要加密数据
     * @returns 加密后的数据
     * @memberof Utils
    */
    encrypt:function(entData){
        const encryptData = JSON.stringify(entData);
        var key = CryptoJS.enc.Utf8.parse('as-Crypto-js')
        var srcs = CryptoJS.enc.Utf8.parse(encryptData)
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString();
    },
    /**
     * CryptoJS 解密
     *
     * @param {String} deData  需要解密数据
     * @returns 解密后的数据
     * @memberof Utils
    */
    decrypt:function(deData){
        var key = CryptoJS.enc.Utf8.parse('as-Crypto-js')
        var decrypt = CryptoJS.AES.decrypt(deData, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return CryptoJS.enc.Utf8.stringify(decrypt).toString()
    }
}
export default passKey;