import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import API from "@/api/index";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vue3dLoader from "vue-3d-loader";
import Clipboard from 'v-clipboard'
import localStorage from '@/utils/userToken';
import moment from "moment";
import aes from '@/utils/crypto.js'
import storage from 'good-storage'
import store from './store'

Vue.use(Clipboard)
Vue.use(vue3dLoader)

import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
Vue.use(LemonIMUI);

Vue.config.productionTip = false
Vue.prototype.$API = API;
Vue.prototype.$localStorage = localStorage;
Vue.prototype.$moment = moment;
moment.locale("zh-cn");
Vue.prototype.$aes = aes;
Vue.prototype.$storage = storage;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
